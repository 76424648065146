import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

const News = ({ node }) => {
    const dateDisplay = node.displayDate.split('T')[0].replace(/-/g,'.');
    return (
      <div>
        <Link
          style={{ color: `#4E2714`, textDecoration: `none` }}
          to={`/${getLangDir(node.node_locale)}/news/${node.slug}/`}
        >
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              fontSize: 14,
              paddingBottom: rhythm(1),
              marginBottom: rhythm(1),
            }}
          >
            <div className="news-list-day">{dateDisplay}</div>
            <div className="news-list-title">{node.title}</div>
          </div>
        </Link>
      </div>
    )
}

const shuffleArticle = (data) => {
  console.log('neeews:', data);
  let articles = [], fixTopIndex = null;
  for (let i=0; i<data.length; i++) {
    if (data[i]['node']['positionTopFixed'] === null || data[i]['node']['positionTopFixed'] === false) {
      articles.push(data[i]);
    } else if (data[i]['node']['positionTopFixed'] == true) {
      fixTopIndex = i;
    }
  }
  if (fixTopIndex !== null) {
    articles.unshift(data[fixTopIndex]);
  }
  return articles;
}

class NewsPage extends React.Component {
  render() {
    const enNewsEdges = shuffleArticle(this.props.data.en.edges);
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet
          title="Cookin’ Burger | Play the game and get DEAPcoin! Multitasking Cooking Game"
          meta={[
            { name: 'description', content: 'New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!' },
            { name: 'keywords', content: "Cookin' Burger, PlayMining, DEAPCoin, Blockchain Game" },
          ]}
        />
        <div className="l-main">

          <div className="section-inner_m">
              <div className="breadcrumb-list wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="0.7s" data-wow-offset="0">
                  <ul>
                      <li><Link to={`/en`}>TOP</Link></li>
                      <li>NEWS</li>
                  </ul>
              </div>
          </div>

          <div style={{ marginBottom: rhythm(2) }}>
            <div className="l-under-section">
              <div className="section-inner_m">
                <div className="l-news-section wow fadeInUp">
                    <ul className="news-list">
                        {enNewsEdges.map(({ node }, i) => (
                        <News node={node} key={node.id} />
                        ))}
                    </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

NewsPage.propTypes = propTypes

export default NewsPage

export const pageQuery = graphql`
  query PageEnNewsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    en: allContentfulPost(limit: 70, filter: { node_locale: { eq: "en-US" } }, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
  }
`